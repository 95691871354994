import React from "react"
import global from 'global'
import document from 'global/document'
import window from 'global/window'


import Layout from "../components/layout.js"
// import Layout from "../components/layout.js"
import SEO from "../components/seo-component.js"

const ContactPage = () => (<>
  <Layout>
    <SEO title="Contact" />
    <div className="container">
      <div className="content">
        <div className="title">
          <h1>Contact</h1>
        </div>

        <div className="row">
          <div className="col-7">
            <p>
              We take our commitment to our users seriously. If you need more information as a potential customer, partner or user, or you need our
              help with your user account, have questions about how to use
              DataPolicyTrust, any feedback or are experiencing technical
              difficulties, please do not hesitate to contact us.
            </p>
          </div>

          <div className="col-5">
            <div className="contact-items">
              <p>Plain email is the best way to contact us.</p>
              <h5>contact@datapolicytrust.com</h5>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
  </>
)

export default ContactPage
